.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 300px;
  height: 300px;
  border-radius: 150px;
  margin-top: 150px;
}
